// src/pages/DmcaPage.js
import React from "react";
import { Link } from 'react-router-dom';
import PageLayout from "../components/PageLayout";
import "../styles/pages/dmca_page.css";

// Define hero content
const heroContent = (
    <img
        src="/images/kitchen-counter-hero.jpg"
        alt="Japanese Cooking Recipes"
    />
);

const DmcaPage = () => {
    return (
        <PageLayout hero={heroContent} showOrnament={false}>
            <div>
                <div className="section">
                    <div className="container">
                        <h1 className="title">Content Removal Request</h1>

                        <div className="content">
                            <section className="mb-6">
                                <h2 className="title is-4">Our Content Policy</h2>
                                <p>
                                    At JapaneseCooking.com, we strongly support content
                                    creators and their rights. Our site operates on
                                    a principle of ethical content sharing:
                                </p>
                                <ul>
                                    <li>
                                        We provide direct links to original YouTube
                                        videos rather than embedding them on our
                                        site
                                    </li>
                                    <li>
                                        For articles and blog posts, we link back to
                                        the original source through RSS feeds
                                    </li>
                                    <li>
                                        Our goal is to help users discover great
                                        content while ensuring that original
                                        creators receive the traffic and recognition
                                        they deserve
                                    </li>
                                </ul>
                            </section>

                            <section className="mb-6">
                                <h2 className="title is-4">
                                    DMCA Takedown Requests
                                </h2>
                                <p>
                                    If you believe that your content is being used
                                    inappropriately on our site, you can submit a
                                    DMCA takedown request using our <Link to="/contact-us" className="contact-us-link">Contact Us form</Link>.
                                </p>
                            </section>

                            <section className="mb-6">
                                <h2 className="title is-4">Required Information</h2>
                                <p>
                                    Your takedown request must include the following
                                    information:
                                </p>
                                <ol>
                                    <li>
                                        A description of the copyrighted work that
                                        you claim has been infringed
                                    </li>
                                    <li>
                                        The exact location (URL) on our website
                                        where you found the infringing material
                                    </li>
                                    <li>
                                        Your contact information, including your
                                        name, email address, and phone number
                                    </li>
                                    <li>
                                        A statement that you have a good faith
                                        belief that the use is not authorized by the
                                        copyright owner, its agent, or the law
                                    </li>
                                    <li>
                                        A statement that the information in your
                                        notice is accurate and, under penalty of
                                        perjury, that you are the copyright owner or
                                        authorized to act on the owner's behalf
                                    </li>
                                    <li>Your physical or electronic signature</li>
                                </ol>
                            </section>

                            <section>
                                <h2 className="title is-4">Our Response</h2>
                                <p>
                                    We take intellectual property rights seriously
                                    and will respond to your request as quickly as
                                    possible. If you have any questions about our
                                    content policies or the takedown process, please
                                    don't hesitate to <Link to="/contact-us" className="contact-us-link">contact us</Link>.
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default DmcaPage;
