import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <Link to="/contact-us">Contact Us</Link>
      <Link to="/dmca">Request Content Removal</Link>
    </footer>
  );
}

export default Footer;
