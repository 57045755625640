// src/pages/ContactPage.js
import React from "react";
import PageLayout from "../components/PageLayout";
import ContactForm from "../components/ContactForm";

const heroContent = (
    <img
        src="/images/kitchen-counter-hero.jpg"
        alt="Japanese Cooking Recipes"
    />
);

const ContactPage = () => {
    return (
        <PageLayout hero={heroContent} showOrnament={false}>
            <div>
                <section className="section">
                    <div className="container">
                        <h1 className="title has-text-centered">Contact Us</h1>
                        <p className="subtitle has-text-centered">
                            Have a question about Japanese cooking? We'd love to
                            hear from you!
                        </p>
                        <ContactForm />
                    </div>
                </section>
            </div>
        </PageLayout>
    );
};

export default ContactPage;
