import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Search, ArrowLeft, ArrowRight } from "lucide-react";
import PageLayout from "../components/PageLayout";
import "../styles/pages/recipe_index_page.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://jc-webservice.onrender.com";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const RecipeIndexPage = () => {
    const query = useQuery();
    const queryPage = Number(query.get("page")) || 1;
    const querySearch = query.get("search") || "";
    const navigate = useNavigate();

    const [recipes, setRecipes] = useState([]);
    const [currentPage, setCurrentPage] = useState(queryPage);
    const [searchTerm, setSearchTerm] = useState(querySearch);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                setLoading(true);
                setError(null);

                const searchParams = new URLSearchParams({
                    page: currentPage.toString(),
                    ...(searchTerm && { search: searchTerm }),
                });

                const response = await fetch(
                    `${API_BASE_URL}/api/recipes?${searchParams}`
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setRecipes(data.recipes);
                setHasMore(data.hasMore);
            } catch (error) {
                console.error("Error fetching recipes:", error);
                setError("Failed to load recipes. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchRecipes();
    }, [currentPage, searchTerm]);

    const updatePage = (newPage) => {
        setCurrentPage(newPage);
        const searchParams = new URLSearchParams({
            page: newPage.toString(),
            ...(searchTerm && { search: searchTerm }),
        });
        navigate(`/recipes?${searchParams}`);
    };

    // Define hero content
    const heroContent = (
        <img
            src="/images/recipes-hero.jpg"
            alt="Japanese Cooking Recipes"
        />
    );

    return (
        <PageLayout hero={heroContent}>
            <div className="columns">
                <div className="column">
                    <h2 className="title is-2">Recipes</h2>

                    <div className="recipe-search">
                        <div className="search-wrapper">
                            <input
                                type="text"
                                placeholder="Search recipes..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Search className="search-icon" size={18} />
                        </div>
                    </div>

                    {error && (
                        <div className="notification is-danger">
                            {error}
                        </div>
                    )}

                    {loading ? (
                        <div className="has-text-centered py-4">
                            <button className="button is-loading">
                                Loading...
                            </button>
                        </div>
                    ) : (
                        <>
                            {recipes.length === 0 && !loading && !error && (
                                <p>No recipes found</p>
                            )}

                            <ul className="recipe-list">
                                {recipes.map((recipe) => (
                                    <li key={recipe.slug}>
                                        <Link to={`/recipes/${recipe.slug}`}>
                                            {recipe.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>

                            <div className="pagination">
                                <button
                                    onClick={() => updatePage(currentPage - 1)}
                                    disabled={currentPage <= 1}
                                >
                                    <ArrowLeft size={16} className="pagination-icon" /> Previous
                                </button>
                                <button
                                    onClick={() => updatePage(currentPage + 1)}
                                    disabled={!hasMore}
                                >
                                    Next <ArrowRight size={16} className="pagination-icon" />
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </PageLayout>
    );
};

export default RecipeIndexPage;