import React from 'react';
import { 
  createBrowserRouter, 
  RouterProvider, 
  ScrollRestoration,
  Outlet
} from 'react-router-dom';
import PrimaryNav from './components/PrimaryNav';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import RecipeIndexPage from './pages/RecipeIndexPage';
import RecipeDetailPage from './pages/RecipeDetailPage';
import ContactPage from './pages/ContactPage';
import DmcaPage from './pages/DmcaPage';
import NotFoundPage from './pages/NotFoundPage';
import ErrorPage from './pages/ErrorPage';
import { Analytics } from '@vercel/analytics/react';

// Trigger rebuild - December 4, 2024 1:17 PM

// Create a root layout component that includes your common elements
const RootLayout = () => {
  return (
    <>
      <section id="page-section" className="section">
        <div className="container-fluid">
          <div className="App">
            <PrimaryNav />
            <Outlet />
            <Footer />
          </div>
        </div>
      </section>
      <ScrollRestoration />
      <Analytics />
    </>
  );
};

// Define your router configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/recipes",
        element: <RecipeIndexPage />
      },
      {
        path: "/recipes/:slug",
        element: <RecipeDetailPage />
      },
      {
        path: "/contact-us",
        element: <ContactPage />
      },
      {
        path: "/dmca",
        element: <DmcaPage />
      },
      {
        path: "/404",
        element: <NotFoundPage />
      },
      {
        path: "/error",
        element: <ErrorPage />
      },
      {
        path: "*",
        element: <NotFoundPage />
      }
    ]
  }
]);

// Your App component becomes much simpler
const App = () => {
  return <RouterProvider router={router} />;
};

export default App;