import { useState } from "react";
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    mode: "onTouched"
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const { submit: onSubmit } = useWeb3Forms({
    access_key: process.env.REACT_APP_WEB3FORMS_KEY,
    settings: {
      from_name: "Japanese Cooking",
      subject: "New Contact Message from Japanese Cooking Website"
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true);
      setMessage(msg);
      reset();
    },
    onError: (msg, data) => {
      setIsSuccess(false);
      setMessage(msg);
    }
  });

  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="box">
            
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="checkbox"
                id=""
                style={{ display: "none" }}
                {...register("botcheck")}
              />

              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input
                    className={`input ${errors.name ? 'is-danger' : ''}`}
                    type="text"
                    placeholder="Your Name"
                    {...register("name", {
                      required: "Full name is required",
                      maxLength: 80
                    })}
                  />
                </div>
                {errors.name && (
                  <p className="help is-danger">{errors.name.message}</p>
                )}
              </div>

              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    className={`input ${errors.email ? 'is-danger' : ''}`}
                    type="email"
                    placeholder="Your Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Please enter a valid email"
                      }
                    })}
                  />
                </div>
                {errors.email && (
                  <p className="help is-danger">{errors.email.message}</p>
                )}
              </div>

              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea
                    className={`textarea ${errors.message ? 'is-danger' : ''}`}
                    placeholder="Your Message"
                    rows="4"
                    {...register("message", {
                      required: "Please enter your message"
                    })}
                  />
                </div>
                {errors.message && (
                  <p className="help is-danger">{errors.message.message}</p>
                )}
              </div>

              <div className="field">
                <div className="control">
                  <button
                    type="submit"
                    className={`button is-primary is-fullwidth ${isSubmitting ? 'is-loading' : ''}`}
                    disabled={isSubmitting}
                  >
                    Send Message
                  </button>
                </div>
              </div>

              {isSuccess && (
                <div className="notification is-success is-light mt-4">
                  {message || "Success! Message sent successfully"}
                </div>
              )}
              {!isSuccess && message && (
                <div className="notification is-danger is-light mt-4">
                  {message || "Something went wrong. Please try again."}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;