import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function PrimaryNav() {
  const location = useLocation();

  return (
    <header>
      <div id="title">
        <Link to="/">
          <h1>Japanese Cooking</h1>
        </Link>
      </div>
      <nav>
        <Link 
          to="/" 
          className={location.pathname === '/' ? 'active' : ''}
        >
          News
        </Link>
        <Link 
          to="/recipes" 
          className={location.pathname.startsWith('/recipes') ? 'active' : ''}
        >
          All Recipes
        </Link>
      </nav>
    </header>
  );
}

export default PrimaryNav;