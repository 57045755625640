// src/pages/ErrorPage.js
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import { Helmet } from 'react-helmet';

const ErrorPage = () => {
    const location = useLocation();
    const message = location.state?.message || "Something went wrong. Please try again later.";
    const navigate = useNavigate();

    const handleTryAgain = () => {
        // Navigate back two steps in history to get to the page before the error
        navigate(-2);
    };

    return (
        <PageLayout>
            <Helmet>
                <title>Error - Japanese Cooking</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <div className="columns is-centered">
                <div className="column is-two-thirds">
                    <div className="box">
                        <h2 className="title">Something Went Wrong</h2>
                        <p className="mb-4">{message}</p>
                        <div className="buttons">
                            <button 
                                onClick={handleTryAgain}
                                className="button is-link is-light"
                            >
                                Try Again
                            </button>
                            <Link to="/recipes" className="button is-light">
                                Browse All Recipes
                            </Link>
                            <Link to="/" className="button is-light">
                                Return Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default ErrorPage;