// src/pages/NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
    return (
        <PageLayout>
            <Helmet>
                <title>Page Not Found - Japanese Cooking</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            
            <div className="columns is-centered">
                <div className="column is-two-thirds">
                    <div className="box">
                        <h2 className="title">Recipe Not Found</h2>
                        <p className="mb-4">
                            Sorry, we couldn't find the recipe you're looking for. It may have been moved or deleted.
                        </p>
                        <div className="buttons">
                            <Link to="/recipes" className="button is-link is-light">
                                Browse All Recipes
                            </Link>
                            <Link to="/" className="button is-light">
                                Return Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default NotFoundPage;