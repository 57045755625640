import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Sidebar from '../components/Sidebar';
import PageLayout from "../components/PageLayout";
import "../styles/pages/home_page.css";

// API base URL - should be in environment config
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://jc-webservice.onrender.com";

const Homepage = () => {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setLoading(true);
                setError(null);

                const response = await fetch(
                    `${API_BASE_URL}/api/news?page=${page}`
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setPosts((prev) => [...prev, ...data.posts]);
                setHasMore(data.hasMore);
            } catch (error) {
                console.error("Error fetching posts:", error);
                setError("Failed to load news. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, [page]);

    return (
        <PageLayout
            hero={
                <img 
                    src="/images/hero.jpg" 
                    alt="Japanese Cooking News" 
                    className="homepage-hero-image"  // We can add specific styling for homepage hero if needed
                />
            }
        >
            <div id="homepage" className="columns">
                <Helmet>
                    <title>
                        Japanese Cooking News - Latest Updates & Trends
                    </title>
                    <meta
                        name="description"
                        content="Stay updated with the latest Japanese cooking news, trends, and updates. Your source for Japanese culinary information."
                    />
                    <meta
                        name="keywords"
                        content="Japanese cooking news, Japanese food trends, Japanese culinary updates, Japanese cooking information"
                    />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                    <meta name="robots" content="index, follow" />
                </Helmet>

                <div className="column is-four-fifths">

                    {error && (
                        <div className="notification is-danger">
                            {error}
                        </div>
                    )}

                    <div className="content">

                        {posts.length === 0 && !loading && !error && (
                            <p>No news articles found</p>
                        )}

                        {posts.map((post) => (
                            <article
                                key={post._id}
                                className="news-article"
                            >
                                <p className="posting-date">
                                    {new Date(
                                        post.date
                                    ).toLocaleDateString("en-US", {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}
                                </p>
                                <h2 className="title is-4">
                                    {post.title}
                                </h2>
                                <p>{post.summary}</p>
                                {post.thumbnails &&
                                    post.thumbnails.maxres && (
                                        <a
                                            href={post.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={
                                                    post.thumbnails
                                                        .maxres.url
                                                }
                                                alt={post.title}
                                            />
                                        </a>
                                    )}
                                <div className="meta">
                                    <span
                                        className="tag is-light"
                                        style={{ display: "none" }}
                                    >
                                        {post.source}
                                    </span>
                                    {post.source === "YouTube" && (
                                        <a
                                            href={post.link}
                                            className="button is-small is-link is-rounded"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View on YouTube →
                                        </a>
                                    )}
                                    {post.source === "RSS" && (
                                        <a
                                            href={post.link}
                                            className="button is-small is-link is-rounded"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View Article →
                                        </a>
                                    )}
                                </div>
                            </article>
                        ))}

                        {loading && (
                            <div className="has-text-centered py-4">
                                <button className="button is-loading">
                                    Loading...
                                </button>
                            </div>
                        )}

                        {hasMore && !loading && !error && (
                            <button
                                className="button is-fullwidth"
                                onClick={() => setPage((p) => p + 1)}
                            >
                                Load More
                            </button>
                        )}
                    </div>
                </div>
                <Sidebar />
            </div>
        </PageLayout>
    );
};

export default Homepage;
