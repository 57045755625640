import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import PageLayout from "../components/PageLayout";
import "../styles/pages/recipe_detail_page.css";
import "../styles/components/breadcrumb.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://jc-webservice.onrender.com";

const RecipeDetailPage = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [recipe, setRecipe] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Fetch effect remains the same...
    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                setIsLoading(true);

                const response = await fetch(`${API_BASE_URL}/api/recipes/${slug}`);

                if (!response.ok) {
                    if (response.status === 404) {
                        navigate('/404');
                        return;
                    }
                    throw new Error(`SERVER_ERROR_${response.status}`);
                }

                const recipeData = await response.json();
                if (!recipeData) {
                    navigate('/404');
                    return;
                }

                setRecipe(recipeData);
            } catch (err) {
                console.error("Error fetching recipe:", err);
                navigate('/error', { 
                    state: { 
                        message: "We're having trouble loading this recipe. Please try again later." 
                    }
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchRecipe();
    }, [slug, navigate]);

    if (isLoading) {
        return (
            <div className="columns">
                <div className="column is-four-fifths">
                    <div className="has-text-centered py-4">
                        <button className="button is-loading">
                            Loading...
                        </button>
                    </div>
                </div>
                <Sidebar />
            </div>
        );
    }

    // Helper functions
    const processIngredients = (ingredients) => {
        if (Array.isArray(ingredients)) {
            return ingredients;
        }
        return ingredients
            .split("\n")
            .map((i) => i.trim())
            .filter((i) => i.startsWith("* "))
            .map((i) => i.replace("* ", ""));
    };

    const processInstructions = (instructions) => {
        if (Array.isArray(instructions)) {
            return instructions;
        }
        return instructions
            .split("\n")
            .map((i) => i.trim())
            .filter((i) => i.startsWith("* "))
            .map((i) => i.replace("* ", ""));
    };

    // Also update the schema generation:
    const generateRecipeSchema = () => {
        const schema = {
            "@context": "http://schema.org",
            "@type": "Recipe",
            name: recipe.title,
            description: recipe.introduction,
            recipeIngredients: Array.isArray(recipe.ingredients)
                ? recipe.ingredients
                : recipe.ingredients
                      .split("\n")
                      .map((i) => i.trim().replace("* ", "")),
            recipeInstructions: Array.isArray(recipe.instructions)
                ? recipe.instructions
                : recipe.instructions
                      .split("\n")
                      .map((i) => i.trim().replace("* ", "")),
            datePublished: recipe.publication_date,
        };
        return JSON.stringify(schema);
    };

    // Process all the data we need
    const ingredients = processIngredients(recipe.ingredients);
    const instructions = processInstructions(recipe.instructions);
    let formattedDate;
    try {
        formattedDate = format(
            new Date(recipe.publication_date),
            "MMMM d, yyyy"
        );
    } catch (err) {
        console.error("Error formatting date:", err);
        formattedDate = "Date unavailable";
    }

    return (
        <PageLayout
            hero={
                <img
                    src="/images/recipes-hero.jpg"
                    alt="Japanese Cooking Recipes"
                />
            }
        >
            <Helmet>
                <title>{`${recipe.title} - Japanese Cooking Recipes`}</title>
                <meta name="description" content={recipe.introduction} />
                <meta
                    name="keywords"
                    content="Japanese cooking, Japanese recipes, authentic Japanese dishes, easy Japanese recipes, cooking Japanese food"
                />
                <script type="application/ld+json">
                    {generateRecipeSchema()}
                </script>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <nav className="jc-breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/recipes">Recipes</Link>
                    </li>
                    <li className="current">{recipe.title}</li>
                </ul>
            </nav>

            <div className="columns">
                <div className="column">
                    <div className="recipe-details">
                        <h2>{recipe.title}</h2>
                        <div className="publication-date">
                            {formattedDate}
                        </div>
                        <p className="introduction">
                            {recipe.introduction}
                        </p>
                        <h3>Ingredients</h3>
                        <ul className="ingredients">
                            {ingredients.map((ingredient, index) => (
                                <li key={index}>{ingredient}</li>
                            ))}
                        </ul>
                        <h3>Instructions</h3>
                        <ol className="instructions">
                            {instructions.map((instruction, index) => (
                                <li key={index}>{instruction}</li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default RecipeDetailPage;
