// src/components/PageLayout.js
import React from 'react';

const PageLayout = ({ hero, children, showOrnament = true }) => {
  return (
    <div className="page-layout">
      {/* Hero section is optional */}
      {hero && (
        <div className="hero-container">
          {hero}
        </div>
      )}
      
      {/* Full-width container for ornamental strip */}
      {showOrnament && (
        <div className="ornamental-strip-container">
          <img
            src="/images/midline_ornamental.webp"
            alt=""
            className="ornamental"
          />
        </div>
      )}

      {/* Main content */}
      <div className="inner-page-container">
        {children}
      </div>
    </div>
  );
};

export default PageLayout;