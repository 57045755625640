import React from 'react';
import { Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import '../styles/components/sidebar.css';

const Sidebar = () => {
    return (
        <div className="column">
            <div className="sidebar-content">
                <h2 className="sidebar-heading">
                    CHEF'S PICKS <Heart className="heart" size={16} color="#B89B7D" />
                </h2>

                <div className="chef-picks-item">
                    <Link to="https://www.amazon.com/WASHO-Sawara-Japanese-Cypress-Otoshibuta/dp/B0DKL2LL98" target='BLANK'>
                        <img 
                            src="/images/products/washo-otoshibuta.webp" 
                            alt="WASHO Kiso Sawara Japanese Cypress Wooden Drop Lid"
                        />
                        <div className="product-info">
                            <div className="product-type">Otoshibuta (Drop Lid)</div>
                            <div className="product-details">
                                WASHO Kiso Sawara Japanese Cypress Wooden Drop Lid Otoshibuta Made in Japan (Medium 7.9" (20 cm))
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="chef-picks-item">
                    <Link to="https://hasuseizo.com/products/sakai-takayuki-kurouchi-hammered-finish-aogami-super-160mm-6-3-kengata-santoku" target='BLANK'>
                        <img 
                            src="/images/products/kitchen-knives-sakai-takayuki-kengata-santoku.webp" 
                            alt="Usuba Knife / Nakiri Knife"
                        />
                        <div className="product-info">
                            <div className="product-type">Sakai Takayuki Kengata Santoku</div>
                            <div className="product-details">
                                160mm (6.3") Aogami Super / Blue Super Kurouchi Hammered Finish
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;