// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bulma/css/bulma.min.css';
import './styles/variables.css';
import './styles/base.css';
import './styles/layout.css';
import "./styles/components/page_layout.css";
import './styles/components.css';  // Then components
import './styles/utilities.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

//<React.StrictMode>
//<App />
//</React.StrictMode>


